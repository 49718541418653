/*
 |-----------------------------------------------------------------------------
 | utils/now.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';

/**
 * Some description...
 *
 * @param year
 *
 */

const now = (year: number) => {
	const now = format(new Date(), 'Y', { locale: enGB });

	return Number(now) > year ? `${year}-${now}` : now;
};

export { now };
