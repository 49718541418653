/*
 |-----------------------------------------------------------------------------
 | utils/transform.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { split } from 'lodash';

/**
 * Some description...
 *
 * @param url
 * @param transformations
 *
 * @returns {`${string}upload/${string}${string}`}
 */

const transform = (url: string, transformations: string) => {
	const parts = split(url, 'upload');

	return `${parts[0]}upload/${transformations}${parts[1]}`;
};

export { transform };
