/*
 |-----------------------------------------------------------------------------
 | utils/getMenu.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { forEach, get } from 'lodash';

/**
 * Some description...
 *
 * @param filename
 * @param menus
 *
 * @returns {*}
 */

const getMenu = (filename: string, menus: Array<object>) => {
	let namedMenu;

	forEach(menus, ({ node }) => {
		if (get(node, ['_sys', 'filename']) === filename) {
			namedMenu = {
				items: get(node, 'items'),
				label: get(node, 'label'),
			};
		}
	});

	return namedMenu;
};

export { getMenu };
