/*
 |-----------------------------------------------------------------------------
 | components/atoms/Hidden/Hidden.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

// import { ray } from 'node-ray/web';

import * as IHidden from './types';

const Hidden: React.FC<IHidden.IProps> = ({ id, isOptional, name, value }) => {
	// ray('Debug atom Hidden:', {
	// 	id: id,
	// 	isOptional: isOptional,
	// 	name: name,
	// 	value: value,
	// }).red();

	return (
		<input
			defaultValue={value}
			id={id}
			name={name}
			required={!isOptional}
			type="hidden"
		/>
	);
};

export default Hidden;
