/*
 |-----------------------------------------------------------------------------
 | components/atoms/Honeypot/Honeypot.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
import classNames from 'classnames';

import styles from './Honeypot.module.scss';

const Honeypot = () => {
	const classes = classNames(get(styles, 'block'));

	return (
		<label className={classes} htmlFor="honeypot">
			<span>Leave blank</span>
			<input id="honeypot" name="honeypot" type="text" />
		</label>
	);
};

export default Honeypot;
