/*
 |-----------------------------------------------------------------------------
 | components/molecules/Navbar/Navbar.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as INavbar from './types';

import Close from '@/atoms/Close';
import HeaderMenu from '@/molecules/HeaderMenu';

import styles from './Navbar.module.scss';

const themes = {
	dark: 'dark',
	light: 'light',
};

const Navbar: React.FC<INavbar.IProps> = ({
	className,
	isOffCanvas,
	menu,
	storybook,
	theme,
}) => {
	const classes = classNames(
		get(styles, themes[theme]),
		{
			[get(styles, 'offCanvas')]: isOffCanvas,
			[get(styles, 'storybook')]: storybook,
		},
		className
	);

	const closeMenu = () => {
		document.querySelector('.jsNavbar').classList.remove('isOpen');
		document.querySelector('.jsContent').classList.remove('isBlurred');
	};

	// ray('Debug molecule Navbar:', {
	// 	classname: classname,
	// 	isOffCanvas: isOffCanvas,
	// 	menu: menu,
	// 	theme: theme,
	// }).blue();

	return (
		<nav
			className={classes}
			itemScope
			itemType="http://schema.org/SiteNavigationElement"
		>
			{isOffCanvas && (
				<Close
					className="close"
					label="Close menu"
					onClick={closeMenu}
				/>
			)}

			{menu && (
				<HeaderMenu
					isOffCanvas={isOffCanvas}
					items={get(menu, 'items')}
					label={get(menu, 'label')}
					theme={theme}
				/>
			)}
		</nav>
	);
};

export default Navbar;
