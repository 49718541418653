/*
 |-----------------------------------------------------------------------------
 | components/organisms/Header/Header.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React, { useEffect, useRef } from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import * as IHeader from './types';
import { getMenu } from '@/utils/getMenu';

import Link from 'next/link';

import Button from '@/atoms/Button';
import Hamburger from '@/atoms/Hamburger';
import Navbar from '@/molecules/Navbar';

import styles from './Header.module.scss';

const themes = {
	dark: 'dark',
	light: 'light',
};

const Header: React.FC<IHeader.IProps> = ({ menus, organisation, theme }) => {
	const headerRef = useRef();

	const isTabletP = useMediaQuery({ query: '(max-width: 1024px)' });
	const isMobileL = useMediaQuery({ query: '(max-width: 414px)' });

	useEffect(() => {
		let lastScrollTop = scrollY;
		window.addEventListener(
			'scroll',
			() => {
				if (headerRef && headerRef.current) {
					const header = headerRef.current.classList;
					const scrollTop =
						window.scrollY || document.documentElement.scrollTop;

					switch (true) {
						case scrollTop === 0:
							header.remove(get(styles, 'isHidden'));
							header.remove(get(styles, 'isSticky'));
							break;
						case scrollTop > lastScrollTop:
							header.remove(get(styles, 'isSticky'));
							header.add(get(styles, 'isHidden'));
							break;
						case scrollTop < lastScrollTop:
							header.remove(get(styles, 'isHidden'));
							header.add(get(styles, 'isSticky'));
							break;
					}

					lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
				}
			},
			false
		);
	}, []);

	const classes = classNames('header', get(styles, themes[theme]));

	const menu = getMenu('header', menus);

	const openMenu = () => {
		document.querySelector('.jsNavbar').classList.add('isOpen');
		document.querySelector('.jsContent').classList.add('isBlurred');
	};

	// ray('Debug organism Header:', {
	// 	menu: menu,
	// 	organisation: organisation,
	// 	theme: theme,
	// }).purple();

	return (
		<header
			className={classes}
			itemScope
			itemType="http://schema.org/Header"
			ref={headerRef}
		>
			<div className={get(styles, 'container')}>
				<Link href="/" passHref>
					<a
						className={get(styles, 'logo')}
						href="jsx-a11y"
						itemScope
						itemType="http://schema.org/Organization"
						rel="home"
						title="Back to Home"
					>
						<svg aria-label={get(organisation, 'name')} role="img">
							<use xlinkHref="/imgs/lgo_sprite.svg#brand"></use>
						</svg>
					</a>
				</Link>

				{!isTabletP && <Navbar menu={menu} theme={theme} />}

				<div className={get(styles, 'wrapper')}>
					{isTabletP && (
						<Hamburger
							label="Open menu"
							onClick={openMenu}
							theme={theme}
						/>
					)}

					{!isMobileL && (
						<Button
							label="Client login"
							variant="primary"
							url="https://castra-5252.cashcalc.co.uk/login"
						/>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
