/*
 |-----------------------------------------------------------------------------
 | components/atoms/Hamburger/Hamburger.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IHamburger from './types';

import styles from './Hamburger.module.scss';

const themes = {
	dark: 'dark',
	light: 'light',
};

const Hamburger: React.FC<IHamburger.IProps> = ({
	className,
	isDisabled,
	label,
	onClick,
	theme,
}) => {
	const classes = classNames(
		get(styles, 'block'),
		get(styles, themes[theme]),
		{
			[get(styles, 'isDisabled')]: isDisabled,
		},
		className
	);

	// ray('Debug atom Hamburger:', {
	// 	className: className,
	// 	isDisabled: isDisabled,
	// 	label: label,
	// 	theme: theme,
	// }).red();

	return (
		<button
			className={classes}
			disabled={isDisabled}
			onClick={onClick}
			type="button"
		>
			<span className="u-screen-reader">{label}</span>
			<span className={get(styles, 'stack')}></span>
		</button>
	);
};

export default Hamburger;
