/*
 |-----------------------------------------------------------------------------
 | components/atoms/Close/Close.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IClose from './types';

import styles from './Close.module.scss';

const Close: React.FC<IClose.IProps> = ({
	className,
	isDisabled,
	label,
	onClick,
}) => {
	const classes = classNames(
		get(styles, 'block'),
		{
			[get(styles, 'isDisabled')]: isDisabled,
		},
		className
	);

	// ray('Debug atom Close:', {
	// 	className: className,
	// 	isDisabled: isDisabled,
	// 	label: label,
	// }).red();

	return (
		<button
			className={classes}
			disabled={isDisabled}
			onClick={onClick}
			type="button"
		>
			<span className="u-screen-reader">{label}</span>
		</button>
	);
};

export default Close;
