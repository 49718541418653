/*
 |-----------------------------------------------------------------------------
 | components/molecules/HeaderMenu/HeaderMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React, { useEffect, useRef } from 'react';

import { forEach, get, map } from 'lodash';
import Link from 'next/link';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import * as IHeaderMenu from './types';
import styles from './HeaderMenu.module.scss';

const themes = {
	dark: 'dark',
	light: 'light',
};

const HeaderMenu: React.FC<IHeaderMenu.IProps> = ({
	className,
	isOffCanvas,
	items,
	label,
	theme,
}) => {
	const router = useRouter();

	const dropdownToggles = useRef([]);

	useEffect(() => {
		const menus = document.querySelectorAll('[role="menu"]');

		const content = document.querySelector('.jsContent');
		const navbar = document.querySelector('.jsNavbar');

		content?.classList.remove('isBlurred');
		navbar?.classList.remove('isOpen');

		forEach(menus, menu => {
			menu.classList.remove(get(styles, 'isVisible'));
			menu.previousElementSibling.setAttribute('title', 'Click to open');
		});
	});

	const classes = classNames(
		get(styles, themes[theme]),
		get(styles, 'lvl0'),
		{
			[get(styles, 'offCanvas')]: isOffCanvas,
		},
		className
	);

	const externalLink = (label, url) => {
		return (
			<a
				className={get(styles, 'item')}
				href={url}
				rel="external noopener noreferrer"
				role="menuitem"
				target="_blank"
			>
				{label}

				<svg className={get(styles, 'icon')} aria-hidden="true">
					<use xlinkHref="/imgs/icn_sprite.svg#external"></use>
				</svg>
			</a>
		);
	};

	const internalLink = (label, path) => {
		return (
			<Link href={path || '#'} passHref>
				<a
					className={
						get(router, 'asPath') === path
							? `${get(styles, 'item')} ${get(
									styles,
									'isCurrent'
							  )}`
							: get(styles, 'item')
					}
					href="jsx-a11y"
					role="menuitem"
				>
					{label}
				</a>
			</Link>
		);
	};

	const toggleDropdown = index => {
		const thisButton = get(dropdownToggles, `current[${index}]`);

		forEach(dropdownToggles.current, toggle => {
			if (toggle) {
				if (toggle === thisButton) {
					thisButton.nextSibling.classList.toggle(
						get(styles, 'isVisible')
					);

					switch (thisButton.getAttribute('title')) {
						case 'Click to close':
							document
								.querySelector('.header')
								.classList.remove('extend');
							thisButton.setAttribute('title', 'Click to open');
							break;
						case 'Click to open':
							document
								.querySelector('.header')
								.classList.add('extend');
							thisButton.setAttribute('title', 'Click to close');
							break;
					}
				} else {
					toggle.nextSibling.classList.remove(
						get(styles, 'isVisible')
					);

					toggle.setAttribute('title', 'Click to open');
				}
			}
		});
	};

	// ray('Debug molecule HeaderMenu:', {
	// 	className: className,
	// 	isOffCanvas: isOffCanvas,
	// 	items: items,
	// 	label: label,
	// 	theme: theme,
	// }).blue();

	return (
		<ul className={classes} aria-label={label} role="menubar">
			{map(items, (item, index) => (
				<li key={index} role="none">
					{get(item, 'url') ? (
						externalLink(get(item, 'label'), get(item, 'url'))
					) : get(item, 'children') ? (
						<>
							<button
								className={get(styles, 'parent')}
								onClick={() => toggleDropdown(index)}
								ref={el =>
									(dropdownToggles.current[index] = el)
								}
								role="menuitem"
								title="Click to open"
							>
								{get(item, 'label')}
							</button>
							<ul className={get(styles, 'lvl1')} role="menu">
								{map(get(item, 'children'), (child, index) => (
									<li key={index} role="none">
										{get(child, 'url')
											? externalLink(
													get(child, 'label'),
													get(child, 'url')
											  )
											: internalLink(
													get(child, 'label'),
													get(child, 'path')
											  )}
									</li>
								))}
							</ul>
						</>
					) : (
						internalLink(get(item, 'label'), get(item, 'path'))
					)}
				</li>
			))}
		</ul>
	);
};

export default HeaderMenu;
