/*
 |-----------------------------------------------------------------------------
 | components/atoms/Attribution/Attribution.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IAttribution from './types';

import styles from './Attribution.module.scss';

const Attribution: React.FC<IAttribution.IProps> = ({
	className,
	name,
	url,
}) => {
	const classes = classNames(get(styles, 'block'), className);

	// ray('Debug atom Attribution:', {
	// 	className: className,
	// 	name: name,
	// 	name: url,
	// }).red();

	return (
		<p className={classes}>
			Website crafted by{' '}
			<a
				className={get(styles, 'link')}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
				title={`Visit ${name}`}
			>
				{name}
			</a>
		</p>
	);
};

export default Attribution;
