/*
 |-----------------------------------------------------------------------------
 | components/molecules/FooterMenu/FooterMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IFooterMenu from './types';

import Link from 'next/link';

import styles from './FooterMenu.module.scss';

const FooterMenu: React.FC<IFooterMenu.IProps> = ({
	className,
	items,
	label,
}) => {
	const classes = classNames(get(styles, 'block'), className);

	const externalLink = (label, url) => {
		return (
			<a
				className={get(styles, 'item')}
				href={url}
				rel="external noopener noreferrer"
				role="menuitem"
				target="_blank"
			>
				{label}

				<svg className={get(styles, 'icon')} aria-hidden="true">
					<use xlinkHref="/imgs/icn_sprite.svg#external"></use>
				</svg>
			</a>
		);
	};

	const internalLink = (label, path) => {
		return (
			<Link href={path || '#'} passHref>
				<a
					className={get(styles, 'item')}
					href="jsx-a11y"
					role="menuitem"
				>
					{label}
				</a>
			</Link>
		);
	};

	// ray('Debug molecule FooterMenu:', {
	// 	classname: classname,
	// 	items: items,
	// 	label: label,
	// }).blue();

	return (
		<ul className={classes} aria-label={label} role="menubar">
			{map(items, (item, index) => (
				<li key={index} role="none">
					{get(item, 'url')
						? externalLink(get(item, 'label'), get(item, 'url'))
						: internalLink(get(item, 'label'), get(item, 'path'))}
				</li>
			))}
		</ul>
	);
};

export default FooterMenu;
