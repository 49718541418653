/*
 |-----------------------------------------------------------------------------
 | components/layouts/Layout/Layout.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, isEmpty } from 'lodash';
import { DefaultSeo, LocalBusinessJsonLd } from 'next-seo';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

import * as ILayout from './types';
import * as schema from '@/utils/schema';
import { fallback } from '@/utils/fallback';
import { getMenu } from '@/utils/getMenu';
import { transform } from '@/utils/transform';

import Footer from '@/organisms/Footer';
import Header from '@/organisms/Header';
import SkipLink from '@/molecules/SkipLink';

import styles from './Layout.module.scss';

const Navbar = dynamic(() => import('@/molecules/Navbar'));

const Layout: React.FC<ILayout.IProps> = ({
	children,
	collection,
	form,
	hideFooter,
	hideHeader,
	menus,
	meta,
	organisation,
	title,
}) => {
	// ray('Debug layout Layout', {
	// 	hideFooter: hideFooter,
	// 	hideHeader: hideHeader,
	// }).orange();

	const router = useRouter();

	const isTabletP = useMediaQuery({ query: '(max-width: 1024px)' });

	let openGraph = {};
	let twitterCard = {};
	if (!isEmpty(get(meta, 'openGraph'))) {
		openGraph = {
			type: 'website',
			url: process.env.NEXT_PUBLIC_SITE_URL + get(router, 'asPath'),
			title:
				get(meta, ['openGraph', 'title']) ||
				get(meta, 'title') ||
				title,
			description:
				get(meta, ['openGraph', 'description']) ||
				get(meta, 'description'),
			images: [
				{
					alt: get(meta, ['openGraph', 'image', 'alt']),
					height: 628,
					url: transform(
						get(meta, ['openGraph', 'image', 'src']),
						'c_crop,h_628,w_1200'
					),
					width: 1200,
				},
			],
		};

		twitterCard = {
			cardType: 'summary',
		};
	}

	let anthology;
	let theme;
	switch (collection) {
		case 'Article':
			anthology = ` · Articles `;
			theme = 'dark';
			break;
		case 'Events':
			anthology = ` · Events `;
			theme = 'light';
			break;
		case 'Gallery':
			anthology = ` · Galleries `;
			theme = 'light';
			break;
		case 'Job':
			anthology = ` · Jobs `;
			theme = 'light';
			break;
		case 'Person':
			anthology = ` · People `;
			theme = 'light';
			break;
		case 'Post':
			anthology = ` · Posts `;
			theme = 'light';
			break;
		case 'Product':
			anthology = ` · Products `;
			theme = 'light';
			break;
		case 'Project':
			anthology = ` · Projects `;
			theme = 'dark';
			break;
		case 'Recipe':
			anthology = ` · Recipes `;
			theme = 'light';
			break;
		case 'Service':
			anthology = ` · Services `;
			theme = 'dark';
			break;
		default:
			anthology = ' ';
			switch (get(router, 'asPath')) {
				case '/':
				case '/contact':
				case '/thank-you':
					theme = 'light';
					break;
				default:
					theme = 'dark';
			}
	}

	return (
		<>
			{process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' ? (
				<DefaultSeo
					title={fallback(get(meta, 'title'), title)}
					titleTemplate={`%s${anthology}| ${get(
						organisation,
						'name'
					)}`}
					description={get(meta, 'description')}
					dangerouslySetAllPagesToNoIndex
					dangerouslySetAllPagesToNoFollow
					openGraph={openGraph}
					twitter={twitterCard}
				/>
			) : (
				<DefaultSeo
					title={fallback(get(meta, 'title'), title)}
					titleTemplate={`%s${collection}| ${get(
						organisation,
						'name'
					)}`}
					description={get(meta, 'description')}
					noindex={get(meta, 'robots')}
					nofollow={get(meta, 'robots')}
					openGraph={openGraph}
					twitter={twitterCard}
				/>
			)}

			{get(organisation, 'type') && (
				<LocalBusinessJsonLd
					// Required props
					address={schema.getAddress(get(organisation, 'addresses'))}
					id={process.env.NEXT_PUBLIC_SITE_URL}
					name={get(organisation, 'name')}
					type={get(organisation, 'type')}
					// Optional props
					description={get(organisation, 'description')}
					geo={schema.getLocation(get(organisation, 'locations'))}
					images={[
						transform(get(organisation, 'image'), 'h_1080,w_1920'),
						transform(get(organisation, 'image'), 'h_90,w_160'),
					]}
					openingHours={schema.getOpeningHours(
						get(organisation, 'openingHours')
					)}
					sameAs={schema.getPlatforms(get(organisation, 'platforms'))}
					telephone={schema.getContactPoint(
						get(organisation, 'contactPoints')
					)}
					url={
						process.env.NEXT_PUBLIC_SITE_URL + get(router, 'asPath')
					}
				/>
			)}

			<SkipLink theme={theme} />

			{!hideHeader && (
				<Header
					menus={menus}
					organisation={organisation}
					theme={theme}
				/>
			)}

			<main
				className={classNames(get(styles, 'content'), 'jsContent')}
				id="main-content"
				itemProp="mainContentOfPage"
				tabIndex={-1}
			>
				{children}
			</main>

			{!hideFooter &&
				get(router, 'asPath') !== '/contact' &&
				get(router, 'asPath') !== '/thank-you' && (
					<Footer
						form={form}
						menus={menus}
						organisation={organisation}
					/>
				)}

			{!hideFooter && isTabletP && (
				<Navbar
					className="jsNavbar"
					isOffCanvas
					menu={getMenu('header', menus)}
					theme={theme}
				/>
			)}
		</>
	);
};

export default Layout;
