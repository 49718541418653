/*
 |-----------------------------------------------------------------------------
 | components/atoms/Copyright/Copyright.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as ICopyright from './types';
import { now } from '@/utils/now';

import styles from './Copyright.module.scss';

const Copyright: React.FC<ICopyright.IProps> = ({ className, name, year }) => {
	const classes = classNames(get(styles, 'block'), className);

	// ray('Debug atom Copyright:', {
	// 	className: className,
	// 	name: name,
	// 	year: year,
	// }).red();

	return (
		<p className={classes}>
			&copy; {now(year)} {name}. All rights reserved.
		</p>
	);
};

export default Copyright;
