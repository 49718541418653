/*
 |-----------------------------------------------------------------------------
 | components/molecules/SkipLink/SkipLink.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as ISkipLink from './types';

import styles from './SkipLink.module.scss';

const themes = {
	dark: 'dark',
	light: 'light',
};

const SkipLink: React.FC<ISkipLink.IProps> = ({ className, theme }) => {
	const classes = classNames(get(styles, themes[theme]), className);

	// ray('Debug molecule SkipLink:', {
	// 	classname: classname,
	// 	theme: theme,
	// }).blue();

	return (
		<a className={classes} href="#main-content">
			Skip to main content
		</a>
	);
};

export default SkipLink;
