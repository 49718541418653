/*
 |-----------------------------------------------------------------------------
 | components/molecules/Form/Form.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

import * as IForm from './types';

import { TinaMarkdown } from 'tinacms/dist/rich-text';

import Hidden from '@/atoms/Hidden';
import Honeypot from '@/atoms/Honeypot';

import styles from './Form.module.scss';

const Button = dynamic(() => import('@/atoms/Button'));
const Checkbox = dynamic(() => import('@/atoms/Checkbox'));
const Input = dynamic(() => import('@/atoms/Input'));
const Radio = dynamic(() => import('@/atoms/Radio'));
const Select = dynamic(() => import('@/atoms/Select'));
const Textarea = dynamic(() => import('@/atoms/Textarea'));

const Form: React.FC<IForm.IProps> = ({
	action,
	className,
	controls,
	title,
}) => {
	const classes = classNames(
		{
			[get(styles, 'enquiries')]: title === 'Enquiries',
			[get(styles, 'subscriptions')]: title === 'Subscriptions',
		},
		className
	);

	let buttonVariant, checkboxVariant;
	switch (title) {
		case 'Enquiries':
			buttonVariant = 'primary';
			checkboxVariant = 'secondary';
			break;
		case 'Subscriptions':
			buttonVariant = 'tertiary';
			break;
	}

	// ray('Debug molecule Form:', {
	// 	action: action,
	// 	className: className,
	// 	controls: controls,
	// 	title: title
	// }).blue();

	return (
		<form
			className={classes}
			acceptCharset="UTF-8"
			action={action}
			data-netlify="true"
			data-netlify-honeypot="honeypot"
			method="post"
			name={title}
		>
			<Hidden
				id="formName"
				isOptional={true}
				name="form-name"
				value={title}
			/>

			<Honeypot />

			{map(controls, (control, index) => {
				switch (get(control, '__typename')) {
					case 'FormControlsButton':
						return (
							<Button
								key={index}
								className="button"
								label={get(control, 'label')}
								type="submit"
								variant={buttonVariant}
							/>
						);
					case 'FormControlsCheckbox':
						return (
							<Checkbox
								key={index}
								className="checkbox"
								hideLabel={get(control, 'hideLegend')}
								isOptional={get(control, 'isOptional')}
								label={get(control, 'label')}
								name={get(control, 'name')}
								options={get(control, 'options')}
								variant={checkboxVariant}
							/>
						);
					case 'FormControlsInput':
						return (
							<Input
								key={index}
								className="input"
								id={get(control, 'id')}
								isOptional={get(control, 'isOptional')}
								label={get(control, 'label')}
								name={get(control, 'name')}
								placeholder=" "
								type={get(control, 'type')}
							/>
						);
					case 'FormControlsRadio':
						return (
							<Radio
								key={index}
								hideLabel={get(control, 'hideLabel')}
								isOptional={get(control, 'isOptional')}
								label={get(control, 'label')}
								name={get(control, 'name')}
								options={get(control, 'options')}
							/>
						);
					case 'FormControlsSelect':
						return (
							<Select
								key={index}
								id={get(control, 'id')}
								isOptional={get(control, 'isOptional')}
								label={get(control, 'label')}
								name={get(control, 'name')}
								options={get(control, 'options')}
							/>
						);
					case 'FormControlsText':
						return (
							<TinaMarkdown
								key={index}
								content={get(control, 'content')}
							/>
						);
					case 'FormControlsTextarea':
						return (
							<Textarea
								key={index}
								className="textarea"
								id={get(control, 'id')}
								isOptional={get(control, 'isOptional')}
								label={get(control, 'label')}
								name={get(control, 'name')}
								placeholder=" "
								rows={get(control, 'rows')}
							/>
						);
				}
			})}
		</form>
	);
};

export default Form;
