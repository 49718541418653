/*
 |-----------------------------------------------------------------------------
 | components/organisms/Footer/Footer.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';

import * as IFooter from './types';
import { getMenu } from '@/utils/getMenu';

import Attribution from '@/atoms/Attribution';
import Copyright from '@/atoms/Copyright';
import FooterMenu from '@/molecules/FooterMenu';
import Form from '@/molecules/Form';

import styles from './Footer.module.scss';

const ContactPoint = dynamic(() => import('@/molecules/ContactPoint'));
const SocialMedia = dynamic(() => import('@/molecules/SocialMedia'));

const Footer: React.FC<IFooter.IProps> = ({ form, menus, organisation }) => {
	const isTabletP = useMediaQuery({ query: '(max-width: 768px)' });

	const classes = classNames(get(styles, 'block'));

	const menu = getMenu('footer', menus);

	// ray('Debug organism Footer:', {
	// 	menu: menu,
	// 	organisation: organisation,
	// }).purple();

	return (
		<footer
			className={classes}
			itemScope
			itemType="http://schema.org/Footer"
		>
			<div className={get(styles, 'signOff')}>
				<div className={get(styles, 'wrapper')}>
					<h2 className={get(styles, 'title')}>Keep in touch</h2>

					{form && (
						<Form
							action={get(form, 'action')}
							controls={get(form, 'controls')}
							title={get(form, 'title')}
						/>
					)}
				</div>
				{!isTabletP && <div></div>}
				<div className={get(styles, 'wrapper')}>
					<h2 className={get(styles, 'title')}>Connect with us</h2>

					{get(organisation, 'platforms') && (
						<SocialMedia
							hideIcons
							platforms={get(organisation, 'platforms')}
							variant="footer"
						/>
					)}
				</div>
				<div className={get(styles, 'wrapper')}>
					<h2 className={get(styles, 'title')}>Contact us</h2>

					{get(organisation, 'contactPoints') &&
						get(organisation, 'contactPoints') &&
						map(
							get(organisation, 'contactPoints'),
							(contactPoint, index) => (
								<ContactPoint
									key={index}
									email={get(contactPoint, 'email')}
									hideLabels
									phone={get(contactPoint, 'phone')}
									variant="footer"
								/>
							)
						)}

					{menu && (
						<FooterMenu
							className="footerMenu"
							items={get(menu, 'items')}
							label={get(menu, 'label')}
						/>
					)}
				</div>
			</div>

			<div className={get(styles, 'colophon')}>
				{get(organisation, 'name') && (
					<Copyright
						className="copyright"
						name={get(organisation, 'name')}
						year={2023}
					/>
				)}

				<Attribution
					className="attribution"
					name="Marka"
					url="https://www.markastudios.co.uk/"
				/>
			</div>
		</footer>
	);
};

export default Footer;
