/*
 |-----------------------------------------------------------------------------
 | utils/fallback.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/**
 * Some description..
 *
 * @param primary
 * @param secondary
 *
 * @returns {*}
 */

const fallback = (primary: object | string, secondary: object | string) => {
	let item;

	if (!primary) {
		item = secondary;
	} else {
		item = primary;
	}

	return item;
};

export { fallback };
